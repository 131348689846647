<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <listitems
          :list="gammes_list"
          :qDelete="Qdelete"
          :qCreate="Qcreate"
          :qUpdate="Qupdate"
          :Get_suf="'Gamme'"
          :title="'Liste des Process'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Process'"
          :exp_excel="false"
          :key="klist"
          :master="false"
          :showstd="true"
          @rowselect="GammeChange"
          :add="editer"
          :Update="editer"
          :del="editer"
          :del_disable="
            (machines_list ? machines_list.length > 0 : true) ||
            (agents_list ? agents_list.length > 0 : true)
          "
          :laoding="loading"
          :push="true"
        >
        </listitems>
      </v-col>
      <v-col cols="12" sm="8" md="8">
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <listitems
              :list="boms_list"
              :title="gamme.sp == 1 ? 'Liste Produits' : bomtitle"
              :qDelete="Qdeletegammebom"
              :Get_suf="'GammeBom'"
              :headers="gamme.sp == 1 ? sp_headers : boms_headers"
              :key="kbom_list"
              :master="false"
              :add="true"
              :del="true"
              :exp_excel="false"
              :add_disable="gamme.id > 0"
              :showstd="false"
              :showedit="false"
              :Update="false"
              :push="true"
              @open="BOMOpen"
              :filename="'Boms'"
              :sheetname="'Bom'"
            >
            </listitems>
          </v-col>
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-row>
              <v-col cols="12" sm="5" md="5">
                <listitems
                  :list="machines_list"
                  :qCreate="Qmachinecreate"
                  :qUpdate="Qmachineupdate"
                  :qDelete="Qmachinedelete"
                  :Get_suf="'GammeMachine'"
                  :title="machinetitle"
                  :headers="machines_header"
                  :key="kml"
                  :master="false"
                  :showstd="true"
                  :add="editer"
                  :add_disable="gamme.id != null"
                  :del="editer"
                  :Update="editer"
                  :filename="'Machines'"
                  :exp_excel="false"
                  :push="true"
                  @add="refresh"
                  @delete="refresh"
                >
                </listitems>
              </v-col>

              <v-col cols="12" sm="7" md="7">
                <listitems
                  :list="agents_list"
                  :qCreate="Qagentcreate"
                  :qUpdate="Qagentupdate"
                  :qDelete="Qagentdelete"
                  :Get_suf="'GammeAgent'"
                  :title="agenttitle"
                  :headers="agents_header"
                  :key="kgl"
                  :master="false"
                  :showstd="true"
                  :add="editer"
                  :add_disable="gamme.id != null"
                  :del="editer"
                  :Update="editer"
                  :filename="'Agents'"
                  :sheetname="'Agents'"
                  :push="true"
                  @add="refresh"
                  @delete="refresh"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <bomform
      :item="bom"
      :items="boms_list"
      :key="kbom"
      :showForm="!isBomClose"
      :gammes="gammes_list"
      :gamme="gamme"
      :attributes="attributes"
      :templates="templates"
      :readonly="false"
      @close="BOMClose"
    >
    </bomform>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import CREATE_GAMME from "../graphql/Production/CREATE_GAMME.gql";
import UPDATE_GAMME from "../graphql/Production/UPDATE_GAMME.gql";
import DELETE_GAMME from "../graphql/Production/DELETE_GAMME.gql";
import DELETE_GAMME_BOM from "../graphql/Production/DELETE_GAMME_BOM.gql";
import CREATE_GAMME_MACHINE from "../graphql/Production/CREATE_GAMME_MACHINE.gql";
import UPDATE_GAMME_MACHINE from "../graphql/Production/UPDATE_GAMME_MACHINE.gql";
import DELETE_GAMME_MACHINE from "../graphql/Production/DELETE_GAMME_MACHINE.gql";

import CREATE_GAMME_AGENT from "../graphql/Production/CREATE_GAMME_AGENT.gql";
import UPDATE_GAMME_AGENT from "../graphql/Production/UPDATE_GAMME_AGENT.gql";
import DELETE_GAMME_AGENT from "../graphql/Production/DELETE_GAMME_AGENT.gql";

import GAMMES from "../graphql/Production/GAMMES.gql";

export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    bomform: () => import("../components/BOMForm.vue"),
  },

  data: () => ({
    loading: false,
    editer: false,
    title: "",
    isMachineClose: true,
    isBomClose: true,
    klist: 400,
    kml: 300,
    kgl: 500,
    kmachine: 200,
    kblist: 1400,
    kbom: 1300,
    kbom_list: 400,
    gamme: {},
    bom: {},
    machine: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    units: [],
    attributes: [],
    templates: [],
    boms_headers: [
      {
        text: "N°",
        value: "no",
        selected: false,
      },
      {
        text: "Caption",
        value: "caption",
        selected: true,
        slot: "href",
      },
      {
        text: "Modele",
        value: "template_name",
        selected: true,
      },
      {
        text: "Semi Fini",
        value: "semi_fini",
        selected: true,
      },
      {
        text: "Multiple",
        value: "multiple",
        selected: true,
        slot: "checkbox",
      },

      {
        text: "Optionnel",
        value: "opt",
        selected: true,
        slot: "checkbox",
      },
    ],
    headers: [
      {
        text: "No.",
        value: "no",
        selected: false,
      },
      {
        text: "Code",
        value: "code",
        edit: true,
        type: "text",
        rules: [(v) => !!v || "Code obligatoire"],
        sm: 2,
        md: 2,
        selected: true,
      },
      {
        text: "Process",
        value: "name",
        rules: [(v) => !!v || "Process obligatoire"],
        slot: "href",
        edit: true,
        type: "text",
        sm: 10,
        md: 10,
        selected: true,
      },
      {
        text: "Statut",
        value: "statut",
        edit: true,
        type: "text",
        rules: [(v) => !!v || "Statut obligatoire"],
        sm: 4,
        md: 4,
        selected: false,
      },
      {
        text: "Caption",
        value: "caption",
        edit: true,
        type: "text",
        rules: [(v) => !!v || "Caption obligatoire"],
        sm: 4,
        md: 4,
        selected: false,
      },
      {
        text: "Cadense(/Heure)",
        value: "cadense",
        slot: "cur",
        align: "end",
        selected: false,
        edit: true,
        type: "number",
        sm: 4,
        md: 4,
        dec: 2,
      },

      {
        text: "Simple Transformation",
        value: "sp",
        edit: true,
        hiden: true,
        type: "check-box",
        slot: "checkbox",
        sm: 3,
        md: 3,
        selected: false,
      },

      {
        text: "Description",
        value: "description",
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
        hiden: true,
      },
    ],
    sp_headers: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "product_code",
        selected: true,
        slot: "href",
      },
      {
        text: "Produit",
        value: "product_name",
        selected: true,
      },

      {
        text: "Unite de Mesure",
        value: "unit_name",
        selected: true,
      },

      // {
      //   text: "Taux de dechet(%)",
      //   value: "dechet",
      //   selected: true,
      // },
    ],
    machines_header: [
      {
        text: "No.",
        value: "no",
        selected: false,
      },
      {
        text: "Ref.",
        value: "ref",
        selected: false,
      },
      {
        text: "Machine",
        value: "machine_id",
        slot: "simple-select",
        edit: true,
        type: "select",
        item_value: "id",
        item_text: "label",
        select_list: [],
        show_list: [],
        rules: [(v) => !!v || "Machine obligatoire"],
        sm: 6,
        md: 6,
        selected: true,
      },
      {
        text: "gamme_id",
        value: "gamme_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    agents_header: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Operateur",
        value: "agent_id",
        slot: "simple-select",
        edit: true,
        type: "autocomplete",
        item_value: "id",
        item_text: "nom",
        select_list: [],
        show_list: [],
        rules: [(v) => !!v || "Operateur obligatoire"],
        sm: 6,
        md: 6,
        selected: true,
      },
      {
        text: "gamme_id",
        value: "gamme_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    gammes_list: [],
    allmachines: [],
    agents: [],
  }),
  watch: {},
  computed: {
    Qdeletegammebom() {
      return DELETE_GAMME_BOM;
    },
    Qcreate() {
      return CREATE_GAMME;
    },
    Qupdate() {
      return UPDATE_GAMME;
    },
    Qdelete() {
      return DELETE_GAMME;
    },
    Qmachinecreate() {
      return CREATE_GAMME_MACHINE;
    },
    Qmachineupdate() {
      return UPDATE_GAMME_MACHINE;
    },
    Qmachinedelete() {
      return DELETE_GAMME_MACHINE;
    },
    Qagentcreate() {
      return CREATE_GAMME_AGENT;
    },
    Qagentupdate() {
      return UPDATE_GAMME_AGENT;
    },
    Qagentdelete() {
      return DELETE_GAMME_AGENT;
    },

    machinetitle() {
      let n = this.gamme.name;
      return n ? "Machines- " + n : "";
    },
    agenttitle() {
      let n = this.gamme.name;
      return n ? "Operateurs- " + n : "";
    },
    bomtitle() {
      let n = this.gamme.name;
      return n ? "BOM- " + n : "";
    },
    boms_list: function () {
      return this.gamme ? this.gamme.boms : [];
    },
    machines_list() {
      return this.gamme ? this.gamme.machines : [];
    },
    machines_gammes() {
      let list = [];
      if (this.gammes_list)
        this.gammes_list.forEach((element) => {
          if (element.machines)
            element.machines.forEach((elm) => {
              list.push(elm.machine_id);
            });
        });
      return list;
    },
    agents_list() {
      return this.gamme ? this.gamme.agents : [];
    },
  },
  created() {},

  async mounted() {
    this.editer =
      this.$store.state.auth.includes("02053") || this.$store.state.isadmin;

    let r = await this.requette(GAMMES);
    if (r) {
      this.gammes_list = r.gammes;
      this.allmachines = r.machines;
      this.agents = r.agents;
      this.units = r.units;
      this.attributes = r.allattributes;
      this.templates = r.alltemplates;
      this.machines_header[2].show_list = this.allmachines;
      this.agents_header[1].show_list = this.agents;
      this.klist++;
    }
  },

  methods: {
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    refresh() {
      if (this.machines_gammes) {
        this.machines_header[2].select_list = this.allmachines.filter(
          (elm) => !this.machines_gammes.includes(elm.id)
        );
      } else {
        this.machines_header[2].select_list = this.allmachines;
      }
      if (this.agents_list) {
        this.agents_header[1].select_list = this.agents.filter(
          (elm) =>
            !this.agents_list.map((elm2) => elm2.agent_id).includes(elm.id)
        );
      } else {
        this.agents_header[1].select_list = this.agents;
      }
    },
    GammeChange(item) {
      this.gamme = item;
      this.machines_header[2].default = this.gamme.id;
      this.agents_header[2].default = this.gamme.id;
      this.refresh();
      this.kml++;
      this.kgl++;
      this.kblist++;
      this.kbom_list++;
    },
    BOMOpen(item) {
      this.bom = item;
      this.bom.fk_gamme = this.gamme.id;
      this.bom.gamme_parent = this.gamme.gamme_id;
      this.isBomClose = false;
      this.kbom++;
    },
    BOMClose() {
      this.isBomClose = true;
      this.kbom++;
    },
  },
};
</script>

<style></style>
